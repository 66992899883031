<template>
  <router-view
    @show-premium-feature-popup="showPremiumFeaturePopup"
  />
</template>
<script>
import PlanMixin from '@/mixins/PlanMixin';
export default {
  mixins: [PlanMixin],
  created() {
    if (this.authUserLoadedAndCannotAccessAdminFeatures) {
      this.$router.push({'name': 'dashboard'});
    }
  },
  methods: {
    showPremiumFeaturePopup(premiumPopupTexts) {
      this.$emit('show-premium-feature-popup', premiumPopupTexts);
    },
  },
};
</script>
